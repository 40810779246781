import { createSlice } from "@reduxjs/toolkit";
import { startOfYesterday, startOfDay } from "date-fns";
import type { StrategyState } from "types/builder.reducer";

const initialState: StrategyState = {
  id: null,
  title: "",
  image: "",
  ticker: 101,
  timeframe: 5,
  spread_type: 0,
  spread_value: 1,
  startDate: startOfDay(new Date(2021, 1, 1)),
  endDate: startOfYesterday(),
  balance: 1000,
  currency: "",
  gain: 0,
  tradesWon: 0,
  tradesLost: 0,
  successRate: 0,
  gainsOnly: 0,
  losesOnly: 0,
  gainLossRate: 0,
  bestGain: 0,
  worstLoss: 0,
  drawdown: 0,
  relativeDrawdown: 0,
  runup: 0,
  relativeRunup: 0,
  origin: null,
  isSaved: false,
  isValidated: false,
  isShared: false,
  dailyDrawdown: 0,
  dailyRunup: 0
};

export const strategySlice = createSlice({
  name: "strategy",
  initialState,
  reducers: {
    reset: () => initialState,
    loadStrategy: (state, action) => {
      const {
        id,
        title,
        ticker,
        timeframe,
        balance,
        startDate,
        isShared = false,
        isSaved = true,
        origin = "SH",
        isValidated = false,
        validation,
        spread_type,
        spread_value,
      } = action.payload;
      if (id) state.id = id;
      if (title) state.title = title;
      if (ticker) state.ticker = ticker;
      if (timeframe) state.timeframe = timeframe;
      if (balance) state.balance = balance;
      state.endDate = startOfYesterday();
      if (startDate) state.startDate = new Date(startDate);
      state.isValidated = isValidated;
      state.isSaved = isSaved;
      state.isShared = isShared;
      state.origin = origin;
      if (spread_type !== undefined) state.spread_type = spread_type;
      if (spread_value !== undefined) state.spread_value = spread_value;
      if (isValidated) {
        state.gain = validation.gain;
        state.tradesWon = validation.tradesWon;
        state.tradesLost = validation.tradesLost;
        state.successRate = validation.successRate;
        state.gainsOnly = validation.gainsOnly;
        state.losesOnly = validation.losses;
        state.gainLossRate = validation.gainLossRate;
        state.bestGain = validation.bestGain;
        state.worstLoss = validation.worstLoss;
        state.drawdown = validation.drawdown;
        state.relativeDrawdown = validation.relativeDrawdown;
        state.runup = validation.runup;
        state.relativeRunup = validation.relativeRunup;
        state.dailyDrawdown = validation.dailyDrawdown;
        state.dailyRunup = validation.dailyRunup;
        state.image = validation.image;
        state.currency = validation.currency;        
      }
    },
    changeTitle: (state, action) => {
      state.title = action.payload;
    },
    changeTicker: (state, action) => {
      state.ticker = action.payload;
      state.isValidated = false;
    },
    changeTimeframe: (state, action) => {
      state.timeframe = action.payload;
      state.isValidated = false;
    },
    changeSpread: (state, action) => {
      state.spread_type = action.payload;
      state.isValidated = false;
    },
    changeFixedSpreadValue: (state, action) => {
      state.spread_value = action.payload;
      state.isValidated = false;
    },
    /**
     * Action used to change validation status.
     */
    changeValidationStatus: (state, action) => {
      const {
        payload: { isValidated = false },
      } = action;
      state.isValidated = isValidated;
    },
    changeStartDate: (state, action) => {
      state.startDate = action.payload;
      state.isValidated = false;
    },
    changeEndDate: (state, action) => {
      state.endDate = action.payload;
      state.isValidated = false;
    },
    changeBalance: (state, action) => {
      state.balance = action.payload;
      state.isValidated = false;
    },
    changeCurrency: (state, action) => {
      state.currency = action.payload;
      state.isValidated = false;
    },
    /**
     * Action used to change the save status of the strategy.
     */
    changeSaveStatus: (state, action) => {
      const {
        payload: { id, saveStatus },
      } = action;
      if (id) state.id = id;
      state.isSaved = saveStatus;
      state.origin = "M";
      state.isShared = false;
    },
    changeImage: (state, action) => {
      state.image = action.payload;
    },
    changeValidation: (state, action) => {
      const { payload } = action;
      state.gain = payload.gain;
      state.tradesWon = payload.trades_win;
      state.tradesLost = payload.trades_loss;
      state.successRate = payload.success;
      state.gainsOnly = payload.gains;
      state.losesOnly = payload.loses;
      state.gainLossRate = payload.gain_loss_rate;
      state.bestGain = payload.best;
      state.worstLoss = payload.wost;
      state.drawdown = payload.drawdown;
      state.relativeDrawdown = payload.relativeDrawdown;
      state.runup = payload.runup;
      state.relativeRunup = payload.relativeRunup;
      state.dailyDrawdown = payload.dailyDrawdown;
      state.dailyRunup = payload.dailyRunup;
      state.image = payload.image;
      state.isValidated = true; // Strategy is now validated
    },
  },
});

export const {
  reset,
  loadStrategy,
  changeTitle,
  changeTicker,
  changeTimeframe,
  changeSpread,
  changeFixedSpreadValue,
  changeStartDate,
  changeEndDate,
  changeBalance,
  changeCurrency,
  changeSaveStatus,
  changeValidation,
  changeValidationStatus,
  changeImage,
} = strategySlice.actions;

export default strategySlice.reducer;
