import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// Frameworks
import {
  Box,
  Typography,
  Modal,
  Fade,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MenuItem from "@mui/material/MenuItem";
import useAppDispatch from "hooks/useAppDispatch";
// import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
// Custom imports
import { show } from "store/reducers/notifications.reducer";
// Assets
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import customAxios from "lib/axios";

const LinkAccount = function () {
  const history = useHistory();
  const localUserId = localStorage.getItem("user_id");
  const dispatch = useAppDispatch();
  const isUserLoggedIn =
    localUserId && !Number.isNaN(localStorage.getItem("user_id"));
  const [t] = useTranslation(["tradingRobots", "errors"]);
  const [showPassword, setShowPassword] = useState(false);
  const [broker, setBroker] = useState("");
  const [server, setServer] = useState("");
  const [login_no_autofill, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [brokerNames, setBrokerNames] = useState([{ BROKER_ID: "", NAME: "" }]);
  const [serverNames, setServerNames] = useState([{ SERVER_ID: "", NAME: "" }]);
  const [selectedBrokerId, setSelectedBrokerId] = useState("");
  const [selectedServerId, setSelectedServerId] = useState("");

  useEffect(() => {
    const apiCalls = async () => {
      const userId = localStorage.getItem("user_id");
      try {
        const respond = await customAxios(
          `/tradingrobots/getBrokers/${userId}`
        );
        setBrokerNames(respond.data);
      } catch (error) {
        dispatch(show({ type: "error", msg: t("errors:serverError") }));
      }
    };

    apiCalls();
  }, [dispatch, t]);

  useEffect(() => {}, [isUserLoggedIn]);

  const getServerList = (brokerid) => {
    const apiCalls = async () => {
      const userId = localStorage.getItem("user_id");
      try {
        const respond = await customAxios(
          `/tradingrobots/getServers/${userId}/${brokerid}`
        );
        setServerNames(respond.data);
      } catch (error) {
        dispatch(show({ type: "error", msg: t("errors:serverError") }));
      }
    };
    if (brokerid !== "") {
      apiCalls();
      return;
    }
  };
  /*
  const onSubmit = async () => {
    const apiCalls = async () => {
      const userId = localStorage.getItem("user_id");
      try {
        const respond = await customAxios(`/tradingrobots/linkAccount/${userId}/${selectedBrokerId}/${selectedServerId}/${login}/${password}`);
        history.push("/tradingrobots/LinkingProgress");
        // history.push("/tradingrobots/main");
      } catch (error) {
        dispatch(show({ type: "error", msg: t("errors:serverError") }));
      }
    };
    apiCalls();
  }

*/

  const onSubmit = () => {
    const userId = localStorage.getItem("user_id");
    customAxios
      .post("/tradingrobots/linkAccount/", {
        userid: userId,
        brokerid: selectedBrokerId,
        serverid: selectedServerId,
        account: login_no_autofill,
        password: password,
      })
      .then((res) => {
        if (res.data.success) {
          history.push("/tradingrobots/LinkingProgress");
        } else {
          if (res.data.message === "Account already linked") {
            dispatch(
              show({ type: "error", msg: t("errors:accountAlreadyLinked") })
            );
          } else {
            dispatch(show({ type: "error", msg: res.data.message }));
          }
        }
      })
      .catch((e) => {
        console.error(e);
        if (
          e.response &&
          e.response.data &&
          e.response.data.message === "Account already linked"
        ) {
          dispatch(
            show({ type: "error", msg: t("errors:accountAlreadyLinked") })
          );
        } else {
          dispatch(
            show({
              type: "error",
              msg: "Error inesperado, contacte con soporte",
            })
          );
        }
      });
  };

  const brokerList = brokerNames.map((curName, i) => (
    <MenuItem
      key={i}
      value={curName.BROKER_ID}
      onClick={() => {
        setSelectedBrokerId(curName.BROKER_ID);
      }}
    >
      {curName.NAME}
    </MenuItem>
  ));
  const serverList = serverNames.map((curName, i) => (
    <MenuItem
      key={i}
      value={curName.SERVER_ID}
      onClick={() => {
        setSelectedServerId(curName.SERVER_ID);
      }}
    >
      {curName.NAME}
    </MenuItem>
  ));
  return (
    <Modal
      open={open}
      disableAutoFocus
      disableRestoreFocus
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(6px)",
      }}
    >
      <Fade in={open}>
        <Box
          bgcolor="#1A2B47"
          px={4}
          py={1}
          width={300}
          height={470}
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          color="white"
          textAlign="center"
          lineHeight="1.2"
          position="relative"
        >
          <HighlightOffIcon
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/");
            }}
          />
          <h2>
            {t("LinkAccount.linkYour")}
            <br />
            {t("LinkAccount.mt4Account")}
          </h2>
          <TextField
            label={t("LinkAccount.broker")}
            color="secondary"
            type="text"
            variant="outlined"
            name="broker"
            onChange={(e) => {
              setBroker(e.target.value);
              getServerList(e.target.value);
            }}
            value={broker}
            InputProps={{
              sx: { color: "secondary.main" },
            }}
            InputLabelProps={{ sx: { color: "secondary.main" } }}
            select
          >
            {brokerList}
          </TextField>

          <TextField
            label={t("LinkAccount.server")}
            color="secondary"
            type="text"
            variant="outlined"
            name="server"
            sx={{ mt: 1 }}
            onChange={(e) => setServer(e.target.value)}
            value={server}
            InputProps={{
              sx: { color: "secondary.main" },
            }}
            InputLabelProps={{ sx: { color: "secondary.main" } }}
            select
          >
            {serverList}
            {/* <MenuItem value={10}>Broker1</MenuItem>
            <MenuItem value={20}>Broker2</MenuItem>
            <MenuItem value={30}>Broker3</MenuItem> */}
          </TextField>

          <TextField
            label={t("LinkAccount.login")}
            color="secondary"
            type="number"
            variant="outlined"
            name="login_no_autofill"
            onChange={(e) => setLogin(e.target.value)}
            value={login_no_autofill}
            sx={{ mt: 1 }}
            InputProps={{
              sx: { color: "secondary.main" },
              autoComplete: "off",
            }}
            InputLabelProps={{ sx: { color: "secondary.main" } }}
          />

          <TextField
            label={t("LinkAccount.password")}
            type={showPassword ? "text" : "password"}
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            sx={{ mt: 1 }}
            color="secondary"
            InputProps={{
              sx: { color: "secondary.main" },
              autoComplete: "new-password",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="secondary"
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    size="small"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ sx: { color: "secondary.main" } }}
          />

          <Button
            sx={{ mt: 2, mb: 1 }}
            // loading={loading}
            variant="contained"
            onClick={onSubmit}
          >
            {t("LinkAccount.linkAccount")}
          </Button>

          <Box>
            <Typography variant="body1" color="secondary" fontWeight={500}>
              {t("LinkAccount.haveNotAccount")}
            </Typography>
            <Typography
              sx={{
                cursor: "pointer",
                textDecorationLine: "underline",
              }}
              component="a"
              color="primary"
              // target="_blank"
              referrerPolicy="no-referrer"
              //onClick={()=>{history.push("/tradingrobots/CreateAccount")}}
              onClick={() => {
                history.push("/tradingrobots/OpenAccount");
              }}
            >
              {t("LinkAccount.createAccountHere")}
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default LinkAccount;
